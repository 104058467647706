import React from 'react';
import styled from 'styled-components';

export default function Title({ children, style = {} }) {
  return <StyledTitle style={style}>{children}</StyledTitle>;
}

const StyledTitle = styled.h1`
  letter-spacing: 0.3rem;
  text-align: center;
`;
