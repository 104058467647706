import React from 'react';
import styled from 'styled-components';

const Paper = ({ children, style = {} }) => (
  <StyledPaper style={style}>
    <div
      style={{
        display        : 'flex',
        width          : '90%',
        flexDirection  : 'column',
        justifyContent : 'center',
        alignItems     : 'center',
      }}>
      {children}
    </div>
  </StyledPaper>
);

const StyledPaper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  min-height: 220px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95%;
  @media (min-width: 600px) {
    width: 90%;
  }
  @media (min-width: 768px) {
    width: 80%;
  }
  @media (min-width: 900px) {
    width: 80%;
  }
  @media (min-width: 1200px) {
    width: 60%;
  }
  @media (min-width: 1400px) {
    width: 60%;
  }
`;

export default Paper;
